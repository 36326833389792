import React from "react";
import MetaTags from 'react-meta-tags';
import {withRouter, Link, useHistory} from "react-router-dom";
import {LogoComponent, CopyrightComponent} from 'ppm-skin'
import {LoginUserComponent} from 'ppm-react-authentication'
import {Col, Container, Row} from "reactstrap";
import {loginSuccess} from "../../store/actions";
import CarouselPage from "./CarouselPage";
import {useDispatch} from "react-redux";
import {PARAMETERS_API_URL, SERVER_BASE_URL, USERS_API_URL,COMPANY_API_URL} from "../../helpers/api-urls";
import logo_ppm from "../../assets/images/logo-ppm.png";
import UserProvier from "security/UserProvier";
// Import menuDropdown
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";
import { withTranslation } from "react-i18next";
import moment from 'moment';
import preval from 'preval.macro';
import packageJson from '../../../package.json';
//import metadata
import metadata from '../../metadata.json';
import axios from "axios";
import {HTTP_RESPONSE} from "../Utility/Helpers/constants";
import fileDownload from 'js-file-download';

const buildTimestamp = preval`module.exports = new Date().getTime();`;
const getDateString = () => {
  const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
  const formattedDate    = lastUpdateMoment.format('DD.MM.YYYY HH:mm:ss');

  return formattedDate;
};

const NewLogin = (props) => {
    const env = process.env.REACT_APP_BRANCH
    const history = useHistory();
    const dispatch = useDispatch();

    const showRegister=true;

    const handleSuccessLogin = (u) => {
        localStorage.setItem("authUser", JSON.stringify(u));
        UserProvier.setToken();
        dispatch(loginSuccess(u));
        history.push('/dashboard');
    }

    const download = async(file) => {
        const url = process.env.REACT_APP_API_BASE_URL + 'userlg/downloadTerm/f=' + encodeURIComponent(file);
        //get the name of the file
        const fileName = file.split('/').pop();
        axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // Important
          }).then((response) => {
            fileDownload(response.data, fileName);
          });
    }

    return (
        <React.Fragment>
            <div>
                <MetaTags>
                    <title>Login LGVP</title>
                </MetaTags>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <CarouselPage xl={9}/>

                        <Col xl={3}>
                            <div className="auth-full-page-content p-md-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100 justify-content-center">
                                        <div className="mb-4 mb-md-5">
                                            <Row>
                                                <Col sm={6} className="mb-4">
                                                   <Link to="#"  onClick={() => download("common/Groupe Legault - Conditions generales d'achat.pdf")} className="font-weight-medium text-primary">{"Conditions générales d'achats"}</Link>
                                                </Col>
                                                <Col sm={6} className="mb-4">
                                                   <Link to="#"  onClick={() => download("common/Legault Group - General Terms & Conditons of Purchase.pdf")} className="font-weight-medium text-primary">{"General Terms & Conditons of Purchase"}</Link>
                                                </Col>
                                            </Row>
                                                <Col sm={4} className="mb-4">
                                                    <LanguageDropdown />
                                                </Col>
                                        </div>
                                        <div className="my-auto">
                                            <div className="d-flex justify-content-center">
                                                <img src={logo_ppm} alt="Logo" width="90%"/>
                                            </div>
                                            <div className="mt-4">
                                                <LoginUserComponent
                                                    apiUrl={USERS_API_URL + '/login'}
                                                    apiCompanyUrl={COMPANY_API_URL}
                                                    onLoginSuccess={(u) => handleSuccessLogin(u)}/>
                                                { showRegister ? (
                                                    <p className="mt-4 text-center">
                                                         {props.t("DO_NOT_HAVE_ACCOUNT")}
                                                        <Link to="/register"
                                                            className="font-weight-medium text-primary">
                                                            {" "}
                                                           
                                                            {props.t("CREATE_ACCOUNT")}
                                                        </Link>{" "}
                                                    </p>
                                                ) : ""
                                                } 
                                                <p className="mt-4 text-center">
                                                <Link to="/forgot-password"
                                                            className="font-weight-medium text-primary">
                                                            {" "}
                                                           
                                                            {props.t("LOST_PASSWORD")} ?
                                                        </Link>{" "}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <Row>
                                                <Col md={12}>{new Date().getFullYear()} © Legault Group.</Col>
                                                <Col md={12} style={{ color: 'red' }}>{env}</Col>
                                                <Col md={12}>
                                                    <div className="text-center d-sm-block">
                                                        LGVP {packageJson.version}
                                                        {'.'}
                                                        {metadata.buildNumber}
                                                        {' '}
                                                        {'('}
                                                        {getDateString()}
                                                        {')'}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};


export default withTranslation()(withRouter(NewLogin));
NewLogin.propTypes = {};
